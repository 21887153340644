import { useRef, useState } from "react";
import ChooseActivityType from "./ChooseActivity";
export default function AddActivity({
  check,
  types,
  handleData2,
  index,
  onClose,
  data,
  handleDataPath,
  widthHeight,
  handleChange,
}) {
  const [Render, setRender] = useState(false);
  const inputRef = useRef();
  if (check)
    return (
      <div className="activityType" style={{left:data[index].type === "button"||data[index].type === "branch"? "-135%" : "-10%"}}>
        <div className="searchDiv1">
          <div className="searchDiv2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              height="24"
              width="40"
              id="Search_Left_svg__Search_Left"
            >
              <path
                fill="currentColor"
                d="M832 772.267 712.533 652.8l-4.267-4.267c38.4-51.2 59.733-110.933 59.733-179.2 0-166.4-132.267-298.667-298.667-298.667S170.665 302.933 170.665 469.333 302.932 768 469.332 768c68.267 0 128-21.333 179.2-59.733 0 0 0 4.267 4.267 4.267l119.467 119.467c8.533 8.533 21.333 12.8 29.867 12.8s21.333-4.267 29.867-12.8c17.067-17.067 17.067-42.667 0-59.733zm-362.667-89.6C349.866 682.667 256 588.8 256 469.334s93.867-213.333 213.333-213.333 213.333 93.867 213.333 213.333c0 119.467-93.867 213.333-213.333 213.333z"
              ></path>
            </svg>
            <input
              value={" "}
              onChange={() => {
                setRender(!Render);
              }}
              ref={inputRef}
              className="searchField"
            ></input>
          </div>
        </div>
        <div className="typesView">
          {types.map((item) => {
            
                return (
                  <ChooseActivityType
                    handleChange={handleChange}
                    handleData3={(item) => handleData2(item)}
                    item={item}
                    index={index}
                    onClose={onClose}
                    data={data}
                    handleDataPath={(item) => handleDataPath(item)}
                    widthHeight={widthHeight}
                  ></ChooseActivityType>
                );
             
          })}
        </div>
      </div>
    );
}
