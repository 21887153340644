import { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import AddActivity from "./AddActivity";
import React from "react";
import Icon from "./ActivityIcon";
// import { da } from "date-fns/locale";

export default function Activity({
  handleData,
  data,
  index,
  pathData,
  setOnActivity,
  setOnButton,
  type,
  handleDataPath,
  reRender,
  setIndex,
  setIsOpen,
  onButton,
  mousePos,
  Child,
  onClose,
}) {
  const [check, change] = useState(false);

  var types = [
    {
      type: "Send message",
    },
    {
      type: "Collect Input",
    },
    {
      type: "Buttons",
    },
    {
      type: "Carousel",
    },
    {
      type: "Calender",
    },
    {
      type: "Send an email",
    },
    {
      type: "Branch",
    },
    {
      type: "Image Carousel",
    },
    {
      type: "Slider",
    },
  ];

  function addActivityButton() {
    check ? change(false) : change(true);
  }

  function onClose() {
    change(false);
  }

  const [color, setColor] = useState();
  const draggableRef = useRef(null);
  const [k, setK] = useState(index);
  const [d, setD] = useState({
    x: data[index].x,
    y: data[index].y,
    type: data[index].type,
  });
  useEffect(() => {
    setD({
      x: data[index].x2,
      y: data[index].y2,
      type: data[index].type,
    });
    setK(Math.random() * 10);
  }, [data.length]);

  const [Out, setOut] = useState(false);
  const [In, setIn] = useState(false);

  const [a, setA] = useState();
  const [b, setB] = useState();
  useEffect(() => {
    const divElement = draggableRef.current.getBoundingClientRect();
    switch (data[index].type) {
      case "Buttons":
        data.push({
          id: data.length,
          type: "button",
          text: "button",
          x:
            data[index].x2 +
            divElement.width / 2 -
            40 +
            (80 + 20) * data[index].children.length,
          y: data[index].y2 + 50 + divElement.height,
          x2:
            data[index].x2 +
            divElement.width / 2 -
            40 +
            (80 + 20) * data[index].children.length,
          y2: data[index].y2 + 50 + divElement.height,
        });
        data[index].children.push(data.length - 1);
        pathData.push({
          x1: data[index].x2 + divElement.width / 2,
          y1: data[index].y2 + divElement.height,
          x2:
            data[index].x2 +
            divElement.width / 2 +
            (80 + 20) * (data[index].children.length - 1),
          y2: data[index].y2 + 50 + divElement.height,
          id1: index,
          id2: data.length - 1,
        });
        data[index].childrenR.push(pathData.length - 1);
        break;
      case "Branch":
        if (data[index].children.length == 0) {
          for (let i = 0; i < 3; i++) {
            data.push({
              id: data.length,
              type: "branch",
              text: `branch${i + 1}`,
              x: data[index].x2 + (80 + 20) * i,
              y: data[index].y2 + 50 + divElement.height,
              x2: data[index].x2 + (80 + 20) * i,
              y2: data[index].y2 + 50 + divElement.height,
            });
            data[index].children.push(data.length - 1);
            pathData.push({
              x1: data[index].x2 + divElement.width / 2,
              y1: data[index].y2 + divElement.height,
              x2: data[index].x2 + 40 + (80 + 20) * i,
              y2: data[index].y2 + 50 + divElement.height,
              id1: index,
              id2: data.length - 1,
            });
            data[index].childrenR.push(pathData.length - 1);
          }

          data.push({
            id: data.length,
            type: "branch",
            text: "default",
            x: data[index].x2 + (80 + 20) * data[index].children.length,
            y: data[index].y2 + 50 + divElement.height,
            x2: data[index].x2 + (80 + 20) * data[index].children.length,
            y2: data[index].y2 + 50 + divElement.height,
          });
          data[index].children.push(data.length - 1);
          pathData.push({
            x1: data[index].x2 + divElement.width / 2,
            y1: data[index].y2 + divElement.height,
            x2:
              data[index].x2 +
              40 +
              (80 + 20) * (data[index].children.length - 1),
            y2: data[index].y2 + 50 + divElement.height,
            id1: index,
            id2: data.length - 1,
          });
          data[index].childrenR.push(pathData.length - 1);
        } else {
          data.push({
            id: data.length,
            type: "branch",
            text: `branch${data[index].children.length - 1}`,
            x: data[index].x2 + (80 + 20) * data[index].children.length,
            y: data[index].y2 + 50 + divElement.height,
            x2: data[index].x2 + (80 + 20) * data[index].children.length,
            y2: data[index].y2 + 50 + divElement.height,
          });
          data[index].children.push(data.length - 1);
          pathData.push({
            x1: data[index].x2 + divElement.width / 2,
            y1: data[index].y2 + divElement.height,
            x2:
              data[index].x2 +
              40 +
              (80 + 20) * (data[index].children.length - 1),
            y2: data[index].y2 + 50 + divElement.height,
            id1: index,
            id2: data.length - 1,
          });
          data[index].childrenR.push(pathData.length - 1);
        }

        break;
      case "Carousel":
        if (data[index].children.length == 0) {
          for (let i = 0; i < 2; i++) {
            data.push({
              id: data.length,
              type: "card",
              text: "card",
              x: data[index].x2 + (80 + 20) * i,
              y: data[index].y2 + 50 + divElement.height,
              x2: data[index].x2 + (80 + 20) * i,
              y2: data[index].y2 + 50 + divElement.height,
            });
            data[index].children.push(data.length - 1);

            pathData.push({
              x1: data[index].x2 + divElement.width / 2,
              y1: data[index].y2 + divElement.height,
              x2: data[index].x2 + 40 + (80 + 20) * i,
              y2: data[index].y2 + 50 + divElement.height,
              id1: index,
              id2: data.length - 1,
            });
            data[index].childrenR.push(pathData.length - 1);
            data.push({
              id: data.length,
              type: "button",
              text: "button",
              x: data[data.length - 1].x2,
              y: data[data.length - 1].y2 + 50 + 40,
              x2: data[data.length - 1].x2,
              y2: data[data.length - 1].y2 + 50 + 40,
            });

            pathData.push({
              x1: data[data.length - 2].x2 + 40,
              y1: data[data.length - 2].y2 + 40,
              x2: data[data.length - 2].x2 + 40,
              y2: data[data.length - 2].y2 + 50 + 40,
              id1: data.length - 2,
              id2: data.length - 1,
            });
          }
        } else {
          data.push({
            id: data.length,
            type: "card",
            text: "card",
            x: data[index].x2 + (80 + 20) * data[index].children.length,
            y: data[index].y2 + 50 + divElement.height,
            x2: data[index].x2 + (80 + 20) * data[index].children.length,
            y2: data[index].y2 + 50 + divElement.height,
          });
          data[index].children.push(data.length - 1);
          pathData.push({
            x1: data[index].x2 + divElement.width / 2,
            y1: data[index].y2 + divElement.height,
            x2:
              data[index].x2 +
              40 +
              (80 + 20) * (data[index].children.length - 1),
            y2: data[index].y2 + 50 + divElement.height,
            id1: index,
            id2: data.length - 1,
          });
          data[index].childrenR.push(pathData.length - 1);
          data.push({
            id: data.length,
            type: "button",
            text: "button",
            x: data[data.length - 1].x2,
            y: data[data.length - 1].y2 + 50 + 40,
            x2: data[data.length - 1].x2,
            y2: data[data.length - 1].y2 + 50 + 40,
          });

          pathData.push({
            x1: data[data.length - 2].x2 + 40,
            y1: data[data.length - 2].y2 + 40,
            x2: data[data.length - 2].x2 + 40,
            y2: data[data.length - 2].y2 + 50 + 40,
            id1: data.length - 2,
            id2: data.length - 1,
          });
        }
        break;
    }
    reRender();
  }, [Child]);
  useEffect(() => {
    if (pathData.length > 0) {
      if (!onButton) {
        for (let i = 0; i < pathData.length; i++) {
          if (pathData[i].id1 === index) {
            setA(i);
            setOut(true);
            break;
          } else {
            setOut(false);
            setA(undefined);
          }
        }

        if (type !== "Start") {
          for (let i = 0; i < pathData.length; i++) {
            if (pathData[i].id2 === index) {
              setB(i);
              setIn(true);

              break;
            } else {
              setIn(false);
              setB(undefined);
            }
          }
        }
      } else {
        setIn(false);
        setOut(false);
      }
    }
  }, [pathData.length]);

  const handleDrag = (e, ui) => {
    console.log(data);
    console.log(a);
    console.log(pathData);
    console.log(data[index].childrenR);
    // Check if the dragging operation is in progress
    const boundingBox = draggableRef.current.getBoundingClientRect();

    const absoluteX = boundingBox.left;

    const absoluteY = boundingBox.top;
    data[index].x2 = absoluteX;
    data[index].y2 = absoluteY;

    if (pathData.length > 0) {
      if (a !== undefined) {
        if (
          data[index].type == "Carousel" ||
          data[index].type == "Buttons" ||
          data[index].type == "Branch"
        ) {
          for (let i = 0; i < data[index].childrenR.length; i++) {
            pathData[data[index].childrenR[i]].x1 =
              absoluteX + boundingBox.width / 2;
            pathData[data[index].childrenR[i]].y1 =
              absoluteY + boundingBox.height;
          }
        } else {
          pathData[a].x1 = absoluteX + boundingBox.width / 2;
          pathData[a].y1 = absoluteY + boundingBox.height;
        }
      }

      if (b !== undefined) {
        pathData[b].x2 = absoluteX + boundingBox.width / 2;
        pathData[b].y2 = absoluteY;
      }
    }

    reRender();
  };
  // const handleRelationDrag = () => {
  //   // Check if the dragging operation is in progress
  //   const boundingBox = draggableRef.current.getBoundingClientRect();

  //   const absoluteX = boundingBox.left;

  //   const absoluteY = boundingBox.top;

  //   pathData[pathData.length - 1].x1 = absoluteX + boundingBox.width / 2;
  //   pathData[pathData.length - 1].y1 = absoluteY + boundingBox.height;
  //   pathData[pathData.length - 1].x2 = mousePos.x;
  //   pathData[pathData.length - 1].y2 = mousePos.y;

  //   reRender();
  // };

  function TopNode() {
    if (In) {
      return (
        <div className="topNode">
          <svg width={24} height={24}>
            <g>
              <circle cx={12} cy={12} r={7} fill="black"></circle>
            </g>
          </svg>
        </div>
      );
    }
  }
  function MakeDes() {
    switch (type) {
      case "Start":
      case "Branch":
      case "Carousel":
      case "Image Carousel":
        return <></>;

      case "Send message":
      case "Collect Input":
      case "Buttons":
      case "Calender":
      case "Send an email":
      case "Slider":
        return (
          <div className="bottom">
            <div>{data[index].description}</div>
          </div>
        );
    }
  }

  function ButtonVisability() {
    switch (data[index].type) {
      case "button":
      case "card":
      case "branch":
        if (Out) {
          return;
        } else {
          return (
            <div
              onClick={() => {
                addActivityButton();
                handleDrag();
              }}
              // onMouseDownCapture={() => {
              //   setOnButton(true);
              //   pathData.push({ id1: index });

              //   console.log(onButton);
              // }}
              className="button"
            >
              <svg width={24} height={24}>
                <g>
                  <circle cx={12} cy={12} r={12} fill="#0053d7"></circle>
                  <path
                    stroke="white"
                    strokeWidth={2.5}
                    d={
                      check
                        ? "M 8.5 8.5 L 15.5 15.5 M 15 8.5 L 8.5 15.5 "
                        : "M 7 13 h 10  M 12 8  v 10"
                    }
                  ></path>
                </g>
              </svg>
            </div>
          );
        }
      default:
        if (Out) {
          return (
            <div className="invisableButton">
              <svg width={24} height={24}>
                <g>
                  <circle cx={12} cy={12} r={7} fill="black"></circle>
                </g>
              </svg>
            </div>
          );
        } else {
          return (
            <div
              onClick={() => {
                addActivityButton();
                handleDrag();
              }}
              // onMouseDownCapture={() => {
              //   setOnButton(true);
              //   pathData.push({ id1: index });

              //   console.log(onButton);
              // }}
              className="button"
            >
              <svg width={24} height={24}>
                <g>
                  <circle cx={12} cy={12} r={12} fill="#0053d7"></circle>
                  <path
                    stroke="white"
                    strokeWidth={2.5}
                    d={
                      check
                        ? "M 8.5 8.5 L 15.5 15.5 M 15 8.5 L 8.5 15.5 "
                        : "M 7 13 h 10  M 12 8  v 10"
                    }
                  ></path>
                </g>
              </svg>
            </div>
          );
        }
    }
  }
  const [aDel, setaDel] = useState(false);
  function DelA() {
    if (type !== "Start") {
      return (
        <div
          className="aDel"
          style={{ visibility: aDel ? "visible" : "hidden" }}
        >
          <div
            className="Icon1"
            onClick={() => {
              const updatedPathData = pathData;

              if (a !== undefined) {
                for (let i = a; i < pathData.length; i++) {
                  updatedPathData[i].id1 = updatedPathData[i].id1 - 1;
                  updatedPathData[i].id2 = updatedPathData[i].id2 - 1;
                }
                updatedPathData.splice(a, 1);
              }
              if (b !== undefined) pathData.splice(b, 1);

              pathData = updatedPathData;

              data.splice(index, 1);

              reRender();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width="20px"
              height="20px"
              fill=""
            >
              <path d="M 21 2 C 19.354545 2 18 3.3545455 18 5 L 18 7 L 10.154297 7 A 1.0001 1.0001 0 0 0 9.984375 6.9863281 A 1.0001 1.0001 0 0 0 9.8398438 7 L 8 7 A 1.0001 1.0001 0 1 0 8 9 L 9 9 L 9 45 C 9 46.645455 10.354545 48 12 48 L 38 48 C 39.645455 48 41 46.645455 41 45 L 41 9 L 42 9 A 1.0001 1.0001 0 1 0 42 7 L 40.167969 7 A 1.0001 1.0001 0 0 0 39.841797 7 L 32 7 L 32 5 C 32 3.3545455 30.645455 2 29 2 L 21 2 z M 21 4 L 29 4 C 29.554545 4 30 4.4454545 30 5 L 30 7 L 20 7 L 20 5 C 20 4.4454545 20.445455 4 21 4 z M 11 9 L 18.832031 9 A 1.0001 1.0001 0 0 0 19.158203 9 L 30.832031 9 A 1.0001 1.0001 0 0 0 31.158203 9 L 39 9 L 39 45 C 39 45.554545 38.554545 46 38 46 L 12 46 C 11.445455 46 11 45.554545 11 45 L 11 9 z M 18.984375 13.986328 A 1.0001 1.0001 0 0 0 18 15 L 18 40 A 1.0001 1.0001 0 1 0 20 40 L 20 15 A 1.0001 1.0001 0 0 0 18.984375 13.986328 z M 24.984375 13.986328 A 1.0001 1.0001 0 0 0 24 15 L 24 40 A 1.0001 1.0001 0 1 0 26 40 L 26 15 A 1.0001 1.0001 0 0 0 24.984375 13.986328 z M 30.984375 13.986328 A 1.0001 1.0001 0 0 0 30 15 L 30 40 A 1.0001 1.0001 0 1 0 32 40 L 32 15 A 1.0001 1.0001 0 0 0 30.984375 13.986328 z" />
            </svg>
          </div>
        </div>
      );
    }
  }

  const [timeoutId, setTimeoutId] = useState(null);
  const clearDelay = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  // Function to set a delay
  const setDelay = () => {
    clearDelay(); // Clear any existing timeouts
    setTimeoutId(
      setTimeout(() => {
        setaDel(false);
      }, 1500)
    );
  };
  try {
    if (
      data[index].type === "button" ||
      data[index].type === "card" ||
      data[index].type === "branch"
    ) {
      return (
        <Draggable
          key={k}
          onDrag={() => {
            handleDrag();
          }}
          disabled={onButton}
        >
          <div
            style={{
              top: d.y,
              left: d.x,
            }}
            ref={draggableRef}
            className="child"
          >
            <div className="childContent">{data[index].text}</div>

            <ButtonVisability />
            <AddActivity
              widthHeight={draggableRef}
              handleData2={(item) => handleData(item)}
              check={check}
              types={types}
              index={index}
              onClose={onClose}
              data={data}
              handleDataPath={(item) => handleDataPath(item)}
              handleChange={setOut}
            ></AddActivity>
            <DelA></DelA>
          </div>
        </Draggable>
      );
    } else {
      return (
        <Draggable
          key={k}
          onDrag={() => {
            setIsOpen(false);
            handleDrag();
          }}
          disabled={onButton}
        >
          <div
            // onMouseEnter={() => {
            //   setOnActivity(true);
            //   setaDel(true);
            //   clearDelay();
            // }}
            // onMouseLeave={() => {
            //   setOnActivity(false);
            //   setDelay();
            // }}
            style={{
              top: d.y,
              left: d.x,
              borderLeft: `3px solid ${color}`,
              zIndex: 1,
            }}
            ref={draggableRef}
            className="container"
            onDoubleClick={() => {
              setIndex(index);
              setIsOpen(true);
            }}
          >
            <TopNode></TopNode>
            <div className="top">
              <div className="iconDiv1">
                <div className="iconDiv2">
                  <Icon
                    type={type}
                    setColor={(color) => setColor(color)}
                  ></Icon>
                </div>
              </div>
              <div className="nameDiv">{data[index].text}</div>
            </div>
            <MakeDes></MakeDes>
            <ButtonVisability />
            <AddActivity
              widthHeight={draggableRef}
              handleData2={(item) => handleData(item)}
              check={check}
              types={types}
              index={index}
              onClose={onClose}
              data={data}
              handleDataPath={(item) => handleDataPath(item)}
              handleChange={setOut}
            ></AddActivity>
            <DelA></DelA>
          </div>
        </Draggable>
      );
    }
  } catch (e) {}
}
