export default function ChooseActivityType({
  handleChange,
  item,
  handleData3,
  index,
  onClose,
  data,
  handleDataPath,
  widthHeight,
}) {
  function Icon() {
    switch (item.type) {
      case "Send message":
        return (
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
          >
            <path
              fill="#e4fafb"
              d="M213.333 85.333h597.333c46.933 0 85.333 38.4 85.333 85.333v426.667c0 46.933-38.4 85.333-85.333 85.333H345.599l-217.6 170.667V170.666c0-46.933 38.4-85.333 85.333-85.333zm298.667 768h341.333z"
            ></path>
            <path
              fill="#34b8ca"
              d="M128 896c-4.267 0-12.8 0-17.067-4.267-17.067-8.533-25.6-21.333-25.6-38.4V170.666c0-72.533 55.467-128 128-128h597.333c72.533 0 128 55.467 128 128v426.667c0 72.533-55.467 128-128 128h-448l-204.8 162.133c-12.8 4.267-21.333 8.533-29.867 8.533zm85.333-768c-25.6 0-42.667 17.067-42.667 42.667V768l149.333-115.2c8.533-8.533 17.067-12.8 25.6-12.8h465.067c25.6 0 42.667-17.067 42.667-42.667V170.666c0-25.6-17.067-42.667-42.667-42.667H213.333zm640 768H512c-25.6 0-42.667-17.067-42.667-42.667S486.4 810.666 512 810.666h341.333c25.6 0 42.667 17.067 42.667 42.667S878.933 896 853.333 896z"
            ></path>
          </svg>
        );
      case "Collect Input":
        return (
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
          >
            <path
              fill="#e7f5fe"
              d="M128 341.333h768v341.333H128V341.333z"
            ></path>
            <path
              fill="#359cec"
              d="M896 768H597.333c-25.6 0-42.667-17.067-42.667-42.667s17.067-42.667 42.667-42.667h256V341.333h-256c-25.6 0-42.667-17.067-42.667-42.667s17.067-42.667 42.667-42.667H896c25.6 0 42.667 17.067 42.667 42.667v426.667C938.667 750.933 921.6 768 896 768zm-640 0H128c-25.6 0-42.667-17.067-42.667-42.667V298.666c0-25.6 17.067-42.667 42.667-42.667h128c25.6 0 42.667 17.067 42.667 42.667S281.6 341.333 256 341.333h-85.333v341.333H256c25.6 0 42.667 17.067 42.667 42.667S281.6 768 256 768z"
            ></path>
            <path
              fill="#ffffff"
              d="M448 170.667v682.667zM341.333 853.333h213.333zm0-682.666h213.333z"
            ></path>
            <path
              fill="#359cec"
              d="M554.667 896H341.334c-25.6 0-42.667-17.067-42.667-42.667s17.067-42.667 42.667-42.667h64V213.333h-64c-25.6 0-42.667-17.067-42.667-42.667s17.067-42.667 42.667-42.667h213.333c25.6 0 42.667 17.067 42.667 42.667s-17.067 42.667-42.667 42.667h-64v597.333h64c25.6 0 42.667 17.067 42.667 42.667S580.267 896 554.667 896z"
            ></path>
          </svg>
        );
      case "Buttons":
        return (
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
          >
            <path
              fill="#fff6d9"
              d="M98.133 209.067h853.333V422.4H98.133V209.067zM98.133 635.733h853.333v213.333H98.133V635.733z"
            ></path>
            <path
              fill="#f5be4f"
              d="M938.667 896H85.334c-25.6 0-42.667-17.067-42.667-42.667V640c0-25.6 17.067-42.667 42.667-42.667h853.333c25.6 0 42.667 17.067 42.667 42.667v213.333c0 25.6-17.067 42.667-42.667 42.667z"
            ></path>
            <path fill="#fff6d9" d="M128 682.667h768v128H128v-128z"></path>
            <path
              fill="#f5be4f"
              d="M938.667 469.333H85.334c-25.6 0-42.667-17.067-42.667-42.667V213.333c0-25.6 17.067-42.667 42.667-42.667h853.333c25.6 0 42.667 17.067 42.667 42.667v213.333c0 25.6-17.067 42.667-42.667 42.667z"
            ></path>
            <path fill="#fff6d9" d="M128 256h768v128H128V256z"></path>
          </svg>
        );
      case "Carousel":
        return (
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
          >
            <path
              fill="#fff6d9"
              d="M341.333 170.667h341.333v426.667H341.333V170.667zm-256 0h128v426.667h-128zm853.334 426.666h-128V170.666h128z"
            ></path>
            <path
              fill="#f5be4f"
              d="M938.667 640h-128c-25.6 0-42.667-17.067-42.667-42.667V170.666c0-25.6 17.067-42.667 42.667-42.667h128c25.6 0 42.667 17.067 42.667 42.667s-17.067 42.667-42.667 42.667h-85.333v341.333h85.333c25.6 0 42.667 17.067 42.667 42.667S964.267 640 938.667 640zm-256 0H341.334c-25.6 0-42.667-17.067-42.667-42.667V170.666c0-25.6 17.067-42.667 42.667-42.667h341.333c25.6 0 42.667 17.067 42.667 42.667v426.667c0 25.6-17.067 42.667-42.667 42.667zM384 554.667h256V213.334H384v341.333zM213.333 640h-128c-25.6 0-42.667-17.067-42.667-42.667s17.067-42.667 42.667-42.667h85.333V213.333H85.333c-25.6 0-42.667-17.067-42.667-42.667s17.067-42.667 42.667-42.667h128c25.6 0 42.667 17.067 42.667 42.667v426.667c0 25.6-17.067 42.667-42.667 42.667zM938.667 853.333h-128c-25.6 0-42.667-17.067-42.667-42.667s17.067-42.667 42.667-42.667h128c25.6 0 42.667 17.067 42.667 42.667s-17.067 42.667-42.667 42.667zm-256 0H341.334c-25.6 0-42.667-17.067-42.667-42.667s17.067-42.667 42.667-42.667h341.333c25.6 0 42.667 17.067 42.667 42.667s-17.067 42.667-42.667 42.667zm-469.334 0h-128c-25.6 0-42.667-17.067-42.667-42.667s17.067-42.667 42.667-42.667h128c25.6 0 42.667 17.067 42.667 42.667s-17.067 42.667-42.667 42.667z"
            ></path>
          </svg>
        );
      case "Calender":
        return (
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
          >
            <path
              fill="#359cec"
              d="M810.667 981.333H213.334c-72.533 0-128-55.467-128-128V256c0-72.533 55.467-128 128-128h597.333c72.533 0 128 55.467 128 128v597.333c0 72.533-55.467 128-128 128zm-597.334-768c-25.6 0-42.667 17.067-42.667 42.667v597.333c0 25.6 17.067 42.667 42.667 42.667h597.333c25.6 0 42.667-17.067 42.667-42.667V256c0-25.6-17.067-42.667-42.667-42.667H213.333z"
            ></path>
            <path
              fill="#359cec"
              d="M896 469.333H128c-25.6 0-42.667-17.067-42.667-42.667S102.4 383.999 128 383.999h768c25.6 0 42.667 17.067 42.667 42.667S921.6 469.333 896 469.333zM682.667 298.667C657.067 298.667 640 281.6 640 256V85.333c0-25.6 17.067-42.667 42.667-42.667s42.667 17.067 42.667 42.667V256c0 25.6-17.067 42.667-42.667 42.667zm-341.334 0c-25.6 0-42.667-17.067-42.667-42.667V85.333c0-25.6 17.067-42.667 42.667-42.667S384 59.733 384 85.333V256c0 25.6-17.067 42.667-42.667 42.667z"
            ></path>
          </svg>
        );
      case "Send an email":
        return (
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
          >
            <path
              fill="#f8f0fc"
              d="M938.667 256 610.134 486.4c-59.733 42.667-136.533 42.667-196.267 0L85.334 256v-42.667c0-46.933 38.4-85.333 85.333-85.333h682.667c46.933 0 85.333 38.4 85.333 85.333V256z"
            ></path>
            <path
              fill="#cb62e4"
              d="M853.333 85.333H170.666c-72.533 0-128 55.467-128 128v512c0 72.533 55.467 128 128 128h682.667c72.533 0 128-55.467 128-128v-512c0-72.533-55.467-128-128-128zM128 213.333c0-25.6 17.067-42.667 42.667-42.667h682.667c25.6 0 42.667 17.067 42.667 42.667v21.333l-311.467 217.6c-42.667 29.867-102.4 29.867-145.067 0L128 234.666v-21.333zM853.333 768H170.666c-25.6 0-42.667-17.067-42.667-42.667V337.066l260.267 183.467c38.4 25.6 81.067 38.4 123.733 38.4s85.333-12.8 123.733-38.4l260.267-183.467v388.267c0 25.6-17.067 42.667-42.667 42.667z"
            ></path>
          </svg>
        );
      case "Branch":
        return (
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
          >
            <path
              fill="#fff6d9"
              d="M426.667 810.667c0 70.692-57.308 128-128 128s-128-57.308-128-128c0-70.692 57.308-128 128-128s128 57.308 128 128zM938.667 810.667c0 70.692-57.308 128-128 128s-128-57.308-128-128c0-70.692 57.308-128 128-128s128 57.308 128 128z"
            ></path>
            <path
              fill="#f5be4f"
              d="M853.333 644.267V640c0-260.267-209.067-469.333-469.333-469.333h-42.667V85.334c0-25.6-17.067-42.667-42.667-42.667s-42.667 17.067-42.667 42.667v558.933c-72.533 17.067-128 85.333-128 166.4 0 93.867 76.8 170.667 170.667 170.667s170.667-76.8 170.667-170.667c0-81.067-55.467-145.067-128-166.4V256H384c213.333 0 384 170.667 384 384v4.267c-72.533 17.067-128 85.333-128 166.4 0 93.867 76.8 170.667 170.667 170.667s170.667-76.8 170.667-170.667c0-81.067-55.467-145.067-128-166.4zM384 810.667C384 857.6 345.6 896 298.667 896s-85.333-38.4-85.333-85.333 38.4-85.333 85.333-85.333S384 763.734 384 810.667zM810.667 896c-46.933 0-85.333-38.4-85.333-85.333s38.4-85.333 85.333-85.333S896 763.734 896 810.667 857.6 896 810.667 896z"
            ></path>
          </svg>
        );
      case "Image Carousel":
        return (
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
          >
            <path
              fill="#e4fafb"
              d="M366.933 409.6h290.133v234.667H366.933V409.6z"
            ></path>
            <path
              fill="#2ab9cd"
              d="M650.667 667.733H364.8c-17.067 0-29.867-12.8-29.867-29.867V411.733c0-17.067 12.8-29.867 29.867-29.867h285.867c17.067 0 29.867 12.8 29.867 29.867v226.133c0 17.067-12.8 29.867-29.867 29.867zm-256-55.466H620.8V441.6H394.667v170.667zM251.733 667.733h-140.8c-17.067 0-29.867-12.8-29.867-29.867s12.8-29.867 29.867-29.867h115.2V437.332h-115.2c-17.067 0-29.867-12.8-29.867-29.867s12.8-29.867 29.867-29.867h140.8c17.067 0 29.867 12.8 29.867 29.867v226.133c0 21.333-12.8 34.133-29.867 34.133zM906.667 667.733h-140.8c-17.067 0-29.867-12.8-29.867-29.867V411.733c0-17.067 12.8-29.867 29.867-29.867h140.8c17.067 0 29.867 12.8 29.867 29.867s-12.8 29.867-29.867 29.867h-115.2v170.667h115.2c17.067 0 29.867 12.8 29.867 29.867s-12.8 25.6-29.867 25.6z"
            ></path>
          </svg>
        );
      case "Slider":
        return (
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
          >
            <path
              fill="#2a7cff"
              d="M379.733 136.533c-59.733 0-110.933 38.4-128 93.867h-128c-21.333 0-42.667 17.067-42.667 42.667 0 21.333 17.067 42.667 42.667 42.667h128c17.067 51.2 68.267 89.6 128 89.6 72.533-4.267 132.267-64 132.267-136.533s-59.733-132.267-132.267-132.267zM601.6 311.467h298.667c21.333 0 42.667-17.067 42.667-42.667 0-21.333-17.067-42.667-42.667-42.667H601.6c-21.333 0-42.667 17.067-42.667 42.667 4.267 25.6 21.333 42.667 42.667 42.667zM900.267 691.2h-72.533c-17.067-55.467-68.267-98.133-128-98.133-72.533 0-132.267 59.733-132.267 132.267S627.2 857.601 699.734 857.601c55.467 0 106.667-34.133 123.733-85.333h76.8c21.333 0 42.667-17.067 42.667-42.667-4.267-21.333-21.333-38.4-42.667-38.4zM422.4 691.2H123.733c-21.333 0-42.667 17.067-42.667 42.667 0 21.333 17.067 42.667 42.667 42.667H422.4c21.333 0 42.667-17.067 42.667-42.667-4.267-25.6-21.333-42.667-42.667-42.667z"
            ></path>
          </svg>
        );
    }
  }
  const divElement = widthHeight.current.getBoundingClientRect();

  function getWidth() {
    return divElement.width / 2;
  }
  function getHeight() {
    return divElement.height;
  }

  return (
    <div
      onClick={() => {
        if (data[index].type === "button" || data[index].type === "branch") {
          console.log("button");
          handleData3({
            id: data.length,
            type: item.type,
            text: item.type,
            children: [],
            childrenR: [],
            description: "value",
            x: data[index].x2,
            y: data[index].y2 + 50 + getHeight(),
            x2: data[index].x2 - divElement.width,
            y2: data[index].y2 + 50 + getHeight(),
          });

          handleDataPath({
            x1: data[index].x2 + getWidth(),
            y1: data[index].y2 + getHeight(),
            x2: data[index].x2 + getWidth(),
            y2: data[index].y2 + 50 + getHeight(),
            id1: index,
            id2: data.length,
          });
        } else {
          handleData3({
            id: data.length,
            type: item.type,
            text: item.type,
            children: [],
            childrenR: [],
            description: "value",
            x: data[index].x2,
            y: data[index].y2 + 50 + getHeight(),
            x2: data[index].x2,
            y2: data[index].y2 + 50 + getHeight(),
          });

          handleDataPath({
            x1: data[index].x2 + getWidth(),
            y1: data[index].y2 + getHeight(),
            x2: data[index].x2 + getWidth(),
            y2: data[index].y2 + 50 + getHeight(),
            id1: index,
            id2: data.length,
          });
        }
        onClose();
        handleChange(true);
      }}
      className="choiceDiv"
    >
      <div className="choice">
        <span>
          <Icon />
        </span>
        <div className="type">
          <p>{item.type}</p>
        </div>
      </div>
      <hr></hr>
    </div>
  );
}
