import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/all.min.css'
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Login from "./features/auth/pages/Login.jsx";


const router = createBrowserRouter([
    {
        path:"/",
        element:<App/>
    }
])


const root = ReactDOM.createRoot(document.getElementById('root'));




root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
    

);