import { useEffect, useState } from "react";
import "./styles/App.css";
import Activity from "./Components/Activity";
import Relation from "./Components/Relation";
import React from "react";
import Slide from "./Components/Slider2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [render, setRerender] = useState(false);
  const [Child, setChild] = useState(false);

  function reRender() {
    setRerender(!render);
  }

  function addChild() {
    setChild(!Child);
  }

  const [dataPath, setDataPath] = useState([]);

  const [count, setCount] = useState(1);
  const [data, setData] = useState([
    {
      id: 0,
      text: "HI",
      description: "Welcome",
      type: "Start",
      x: 400,
      y: 0,
      x2: 400,
      y2: 0,
    },
  ]);
  // const [mousePos, setMousePos] = useState({});

  const [jsonData, setJsonData] = useState([]);

  // function handleData(item) {

  //   // // Increment the count to generate a new id
  //   // const newId = count + 1;

  //   // // Create a new item with the updated id
  //   // const newItem = { ...item, id: newId };
  
  //   // Update the count
  //   setCount(count+1);

  //   // update the whole data 
  //   setData([...data, count]);
  // }

  function handleData(item) {
    const newCount = data.length + 1 ;
    setCount(newCount);
    console.log("all counts",count);
    setData([...data, item]);
  }


  function handleDataPath(item) {
    setDataPath([...dataPath, item]);
  }

  const [index, setIndex] = useState(undefined);

  const [isOpen, setIsOpen] = useState(false);

  const [dragRelation, setDragRelation] = useState(false);

  const [onButton, setOnButton] = useState(false);
  const [onActivity, setOnActivity] = useState(false);


  // tony

   // Function to fetch 'id' and 'message' from externalData
   const fetchIdAndMessage = async () => {
 
    console.log("kol al data" , data , dataPath);


    // Convert data and dataPath to JSON
// const jsonData = JSON.stringify(data);
// const jsonPath = JSON.stringify(dataPath);



// Merge the JSON data
// const mergedData = JSON.parse(jsonData);
// // const mergedPath = JSON.parse(jsonPath);

// Combine the merged data and merged path
// const mergedResult = {
//   ...mergedData,
//   ...mergedPath
// };

// Console log the merged result
// console.log(jsonData);
// console.log("##################################");
// console.log(jsonPath);


// Combine the two arrays
// const combinedArray = data.concat(dataPath);

// Create a map to keep track of options for each id
// const optionsMap = {};

// combinedArray.forEach((item) => {
//   const { id, text, type, x2 } = item;

//   if (type === "Start" || type === "Send message" || type === "Buttons") {
//     optionsMap[id] = optionsMap[id] || { id };
//     if (text) optionsMap[id].message = text;
//   } else if (type === "button") {
//     const option = { value: text, label: text, trigger: x2.toString() };
//     if (!optionsMap[id].options) {
//       optionsMap[id].options = [];
//     }
//     optionsMap[id].options.push(option);
//   }
// });

// Create the final result array
// const finalResult = Object.values(optionsMap);

// console.log("waryny kdh" , finalResult);


// const mergedArray = [...jsonData, ...jsonPath];
// const mergedJsonString = JSON.stringify(mergedArray);

// console.log("merged array",mergedJsonString)

// const data1 = JSON.parse(jsonData);
// const data2 = JSON.parse(jsonPath);

// // Concatenate the arrays from data1 and data2 into one large array
// const concatenatedData = data1.concat(data2);

// // Create a new JSON object with the concatenated array
// const mergedJson = { Task: concatenatedData };

// // Convert the merged JSON object to a JSON string
// const mergedJsonString = JSON.stringify(mergedJson);

// console.log(mergedJsonString);

// const task = {Task: data} ;
// const pathTask = {PathTask: dataPath};


// const concatenatedArray = [...task, ...pathTask];

console.log("al data" , JSON.stringify(data));
console.log("al path" ,JSON.stringify(dataPath) );

const finalObject = {
  Task: data,
  PathTask: dataPath,
};

console.log("a5ls" , JSON.stringify(finalObject));

// console.log("concatenatedArray", concatenatedArray);


     // Extract 'id' and 'message' properties from externalData
    //  const selectedValues = data.map((item, index, array) => {
    //   if (item.type === "Start" || item.type === "Send message")
    //   {
    //       const isLastElement = index === array.length - 1;
      
    //       if (isLastElement) {
    //         return { "id": item.id, "message": item.description };
    //       } else {
    //         return { "id": item.id, "message": item.description, "trigger": array[index+1].id };
    //       }
    //   }
    //   else if (item.type === "Buttons"){
    //     return { "id": item.id, "options":  [
    //       {
    //         value: "appointments",
    //         label: "a Appointment",
    //         trigger: "5"
    //       },
    //     ] };
    //   }
    
    // }); 

    // solution 

    
// const transformedData = [];

//   data.forEach((item, index, array) => {
//       if (item.type === "Start" || item.type === "Send message") {
//         const isLastElement = index === array.length - 1;
//         const transformedItem = { id: item.id, message: item.description };

//         if (!isLastElement) {
//           transformedItem.trigger = array[index + 1].id;
//         }

// // transformedData.push(transformedItem);  
// //       } else if (item.type === "Buttons") {
// //         const childrenId = item.children ;
        
// //         console.log("children id" ,childrenId);
        
//         // const allOptions = array.filter(childItem => item.type === "button");
        
//         // const allOptions = array.filter((el)=>{
//         //   return(
//         //     el.type === "button"
//         //   )
//         // })
//         // console.log(allOptions);
        
//         // const options = allOptions
//         //     .filter(el => childrenId.includes(el.id))
//         //     .map(el => ({
//         //       value: "test",
//         //       label: el.text,
//         //       trigger: el.id
//         //     }));
//         // console.log("al options" , options);
        
        
//         // const options = array
//         //   .filter(childItem => item.type==="button")
//         //   .map(childItem => {
//         //     return {
//         //       value: item.text, 
//         //       label: "test", 
//         //       trigger: childItem.id
//         //     };
//         //   });
      
        

//         // transformedData.push({ id: item.id, options });
//       }
// });


    // end of solution




    // [{"description":"welcome" , "id":"1" , "text":"HI" , "type": "Start"},{"description":"Kol al buttons" , "id":"2" , "text":"buttons" , "type": "Buttons" ,"children": [3,4]},{"description":"awl button" , "id":"3" , "text":"button" , "type": "Button"},{"description":"tany button" , "id":"4" , "text":"button" , "type": "Button"},{"description":"totos" , "id":"4" , "text":"Hello" , "type": "Send message"}]

    //  Create a new data object with the selected 'id' and 'message' values for the 'body' property
     const dynamicData = 
       {
         AccountNumber: "10000",
         body: finalObject
       };
 
    //  setTheJsonData(dynamicData);
    // console.log("kareem" , dynamicData);
     console.log("wala ya wala", JSON.stringify(dynamicData));

     const res = await fetch(`https://chatbotapi.mygatein.com/chatbot/KBot/FN1?iddata=${JSON.stringify(dynamicData)}`, {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            
          },
        });
        const data3 = await res.json();

        console.log("dataaaaa jo",data3);

    console.log("al path" , dataPath);

    // al ids  bta3t al buttons 
    // const buttonsIds = [];

    // // al update hna bt3ml -1 ll buttons 34an twsl lly ablo
    // const updatedArray = data.map((obj) => {
    //   if (obj.type === "Buttons") {
    //     buttonsIds.push(obj.id);
    //     return { ...obj, id: obj.id - 1 };
    //   }
    //   return obj;
    // });


    // dh hna ana b3dl al path wb 4yl al path bta3 al buttons
    // const updatedArray2 = dataPath.map(obj => {
    //   if (buttonsIds.includes(obj.id1)) {
    //     return { ...obj, id1: obj.id1 - 1 };
    //   }
    //   return obj;
    // }).filter(obj => !buttonsIds.includes(obj.id2));

    // // b4yl kol 7aga  ma 3ada  id1 & id2 w 8yrt al asm l id w trigger
    // const updatedArray3 = updatedArray2.map(({ id1, id2 }) => ({ id: id1, trigger: id2 }));


    // hnzwd al message fy al object  ma 3ada aly asmohom button
    // const updatedArray4 = updatedArray3.map((item) => {
    //   const matchingPassedItem = data.find(
    //     (passedItem) => passedItem.id === item.id
    //   );
    
    //   if (matchingPassedItem && matchingPassedItem.type !== "button") {
    //     return { ...item, message: matchingPassedItem.description };
    //   }
    //   return item;
    // });


    // const buttonsArray = updatedArray.filter(obj => obj.type === "Buttons");
    // const nonButtonsArray = updatedArray.filter(obj => obj.type !== "Buttons");


    // // dh hna al children bto3y 
    // const childrenArray = buttonsArray.map(obj => obj.children);

    // // kolo fy array wa7d kdh 
    // const allChildren = [].concat(...buttonsArray.map(obj => obj.children));



    // // al array bl options 
    // const newArray = updatedArray3.reduce((accumulator, obj) => {
    //   const found = accumulator.find(item => item.id === obj.id);
    //   const trigger = obj.trigger;
    
    //   if (allChildren.includes(trigger)) {
    //     // If the trigger exists in allChildren, add it to the options
    //     if (found) {
    //       found.options.push({ trigger: `${trigger}`, value: trigger });
    //     } else {
    //       accumulator.push({ id: obj.id, options: [{ trigger: `trigger "${trigger}"`, value: trigger }] });
    //     }
    //   } else {
    //     // If the trigger doesn't exist in allChildren, add just the "id" and keep the "trigger" from the passed array
    //     if (!found) {
    //       accumulator.push({ id: obj.id, trigger: obj.trigger });
    //     }
    //   }
    
    //   return accumulator;
    // }, []);

    
    // console.log("buttons -1" , updatedArray);
    // console.log("the buttons id" , buttonsIds);
    // console.log("updated path" , updatedArray2);
    // console.log("updated id, trigger" , updatedArray3);
    // // console.log("updated message" , updatedArray4);
    // console.log("buttons array" , buttonsArray);
    // console.log("no buttons array" , nonButtonsArray);
    // console.log("children Array" , childrenArray);
    // console.log("all Children" , allChildren);
    // console.log("new  array" , newArray);


    toast.success('Data saved successfully!', {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

   };
 
   // Convert the JSON data to a string if needed
  //  const jsonString = JSON.stringify(theJsonData);
 
  return (
    <div
      style={{ width: "100%", height: "100%", overflow: "visible" }}
      //       onMouseUpCapture={() => {
      //         if (!onActivity) {
      // setOnButton(false)
      //           setDataPath(dataPath.splice(dataPath.length - 1, 1));
      //           reRender();
      //           console.log(dataPath);
      //           console.log(onActivity);
      //         }
      //       }}
    >
      <div style={{ height: 150, width: 300 }}>
        {data.map((items, index) => (
          <Activity
            key={index}
            handleData={(item) => handleData(item)}
            handleDataPath={(item) => handleDataPath(item)}
            x={items.x}
            y={items.y}
            data={data}
            count={count}
            index={index }
            type={items.type}
            pathData={dataPath}
            reRender={reRender}
            Child={Child}
            setIndex={(item) => setIndex(item)}
            setIsOpen={setIsOpen}
            setOnButton={setOnButton}
            setOnActivity={setOnActivity}
            onButton={onButton}
            // mousePos={mousePos}
          ></Activity>
        ))}
      </div>

      <svg
        className="pathSvg"
        style={{ overflow: "visible", position: "absolute", top: 0, left: 0 }}
      >
        {dataPath.map((_item, index) => (
          <Relation
            key={index}
            reRender={reRender}
            pathData={dataPath}
            index={index}
            data={data}
            // mousePos={mousePos}
          ></Relation>
        ))}
      </svg>

      <Slide
        reRender={reRender}
        data={data}
        pathData={dataPath}
        index={index}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        addChild={addChild}
      ></Slide>
      <div>
      <button className="saveBtn" onClick={fetchIdAndMessage}>save</button>
      <ToastContainer/>
      </div>
      
    </div>
    // [{"id":0,"text":"HI","description":"Welcome","type":"Start","x":400,"y":0,"x2":400,"y2":0},{"id":1,"type":"Send message","text":"Send message","children":[],"childrenR":[],"description":"value","x":400,"y":122,"x2":400,"y2":122},{"id":2,"type":"Buttons","text":"Buttons","children":[3,4],"childrenR":[2,3],"description":"value","x":400,"y":274,"x2":94,"y2":220},{"id":3,"type":"button","text":"button","x":481,"y":426,"x2":92,"y2":348},{"id":4,"type":"button","text":"button","x":581,"y":426,"x2":526,"y2":218},{"id":5,"type":"Send message","text":"Send message","children":[],"childrenR":[],"description":"value","x":92,"y":438,"x2":12,"y2":438},{"id":6,"type":"Send message","text":"Send message","children":[],"childrenR":[],"description":"value","x":560,"y":402,"x2":518,"y2":285},{"id":7,"type":"Buttons","text":"Buttons","children":[8,9],"childrenR":[7,8],"description":"value","x":470,"y":493,"x2":281,"y2":440},{"id":8,"type":"button","text":"button","x":551,"y":645,"x2":389,"y2":579},{"id":9,"type":"button","text":"button","x":651,"y":645,"x2":537,"y2":578}]
    // [{"x1":521,"y1":72,"x2":422,"y2":114,"id1":0,"id2":1},{"x1":422,"y1":216,"x2":277,"y2":255,"id1":1,"id2":2},{"x1":277,"y1":357,"x2":168,"y2":417,"id1":2,"id2":3},{"x1":277,"y1":357,"x2":584,"y2":269,"id1":2,"id2":4},{"x1":168,"y1":457,"x2":168,"y2":507,"id1":3,"id2":5},{"x1":584,"y1":309,"x2":602,"y2":332,"id1":4,"id2":6},{"x1":602,"y1":434,"x2":518,"y2":459,"id1":6,"id2":7},{"x1":518,"y1":561,"x2":494,"y2":609,"id1":7,"id2":8},{"x1":518,"y1":561,"x2":654,"y2":612,"id1":7,"id2":9}]    
    );
}

export default App;
