import React, { useState, useRef, useEffect } from "react";
import { htmlToText } from "html-to-text";
import "../styles/slider.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import validator from "validator";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
export default function Slide(props) {
  const [message, setMessage] = useState();
  const [textArea, setTextArea] = useState("");
  const handleMessageChange = (e) => {
    const newValue = e.target.value;
    setMessage(newValue);
    props.handleMessage(newValue);
  };
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: false, // Prevent converting spaces to new lines
    },
  };
  const [inputBtn, setInputBtn] = useState("Button");
  const formats = ["bold", "italic", "underline", "list", "link", "image"];
  const [editorContent, setEditorContent] = useState([""]);
  const handleEditorChange = (html) => {
    const text = htmlToText(html, {
      wordwrap: 130, // Adjust this to control line length
    });
    return text;
  };
  const addEditor = () => setEditorContent([...editorContent, ""]);
  const deleteEditor = (index) => {
    const newContent = [...editorContent];
    if (index > 0) {
      newContent.splice(index, 1);
      setEditorContent(newContent);
    }
  };
  const fileInputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileQueue, setFileQueue] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const newSelectedFiles = Array.from(files);
    setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    setFileSelected(true);
  };

  const handleCombinedButtonClick = () => {
    if (fileSelected) {
      const fileToUpload = selectedFiles[0];
      // Simulate an upload (you can replace this with actual upload logic)
      setTimeout(() => {
        console.log("Uploading:", fileToUpload.name);
        // Remove the uploaded file from the selected files and add it to the file queue
        setSelectedFiles(selectedFiles.slice(1));
        setFileQueue([...fileQueue, fileToUpload]);
        setFileSelected(false);
      }, 1000); // Simulating a delay for the upload
    } else {
      // If no files are selected, trigger the file input to allow users to select files
      fileInputRef.current.click();
    }
  };
  const handleDeleteButtonClick = (index) => {
    const updatedQueue = [...fileQueue];
    updatedQueue.splice(index, 1);
    setFileQueue(updatedQueue);
  };
  const [drobDown, setDrobDown] = useState(false);
  const handleDrobDown = () => {
    setDrobDown(!drobDown);
  };
  const [send, setSend] = useState("");
  const [isSendValid, setIsSendValid] = useState(true);
  const [cc, setCC] = useState("");
  const [isCCValid, setIsCCValid] = useState(true);
  const [bcc, setBCC] = useState("");
  const [isBCCValid, setIsBCCValid] = useState(true);

  const handleSendChange = (e) => {
    const newEmail = e.target.value;
    setSend(newEmail);
    setIsSendValid(validator.isEmail(newEmail));
  };
  const handleCCChange = (e) => {
    const newEmail = e.target.value;
    setCC(newEmail);
    setIsCCValid(validator.isEmail(newEmail));
  };
  const handleBCCChange = (e) => {
    const newEmail = e.target.value;
    setBCC(newEmail);
    setIsBCCValid(validator.isEmail(newEmail));
  };
  const [dateType, setDateType] = useState("selection");
  const handleDateType = (e) => {
    const type = e.target.id;
    setDateType(type);
  };

  const [date, setDate] = useState(new Date());
  const [dateDrop, setDateDrop] = useState(false);
  const handleDateDrob = () => {
    setDateDrop(!dateDrop);
  };
  let type;

  const SendMessageRef = useRef();
  useEffect(() => {
    try {
      SendMessageRef.current.value = props.data[props.index].text;
      editorContent[0] = props.data[props.index].description;
    } catch (e) {}
  }, [props.index]);
  if (props.isOpen) type = props.data[props.index].type;
  let Text = "";
  switch (type) {
    default:
      return null;
    case "Send message":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                ref={SendMessageRef}
                onChange={() => {
                  props.reRender();
                  props.data[props.index].text = SendMessageRef.current.value;
                }}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          {editorContent.map((content, index2) => (
            <div className="slider-comp">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="message-description">Send this message</p>
                <button
                  className="delet-btn"
                  onClick={() => deleteEditor(index2)}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: index2 === 0 ? "auto" : "pointer",
                  }}
                >
                  <i
                    className="fa-regular fa-trash-can"
                    style={{ color: index2 === 0 ? "transparent" : "gray" }}
                  ></i>
                </button>
              </div>
              <div className="quill">
                <ReactQuill
                  key={index2}
                  placeholder="what is your..."
                  theme="snow"
                  value={content}
                  onChange={(content) => {
                    editorContent[index2] = handleEditorChange(content, index2);
                    props.data[props.index].description = editorContent[0];
                  }}
                  modules={modules}
                  formats={formats}
                />
              </div>
            </div>
          ))}
          <div>
            <div>
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileUpload}
                ref={fileInputRef}
              />
              <ul style={{ padding: "0" }}>
                {fileQueue.map((file, index) => (
                  <li
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    {file.name}
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteButtonClick(index)}
                    >
                      <i className="fa-regular fa-trash-can"></i>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="adding-btns">
            <button onClick={addEditor}>
              <i class="fa-solid fa-plus"></i>
              <p className="add-btn-text">Text</p>
            </button>
            <button onClick={handleCombinedButtonClick}>
              <i class="fa-solid fa-plus"></i>
              <p className="add-btn-text">{fileSelected ? "Upload" : "File"}</p>
            </button>
          </div>
        </div>
      );
    case "Collect Input":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                ref={SendMessageRef}
                onChange={() => {
                  props.reRender();
                  props.data[props.index].text = SendMessageRef.current.value;
                }}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          <div className="slider-comp">
            <p className="message-description">Send this message</p>
          </div>
          <div className="quill">
            <ReactQuill
              placeholder="what is your..."
              value={textArea}
              onChange={() => {
                console.log(handleEditorChange(textArea));
                setTextArea(textArea);
                // props.data[props.index].description = textArea;
              }}
              theme="snow"
              modules={modules}
              formats={formats}
            />
          </div>
          <div className="drop-down slider-comp">
            <p className="message-description">
              Save the response in this variable
            </p>
            <input
              className="text-box"
              type="text"
              readOnly
              placeholder="Select"
              onClick={handleDrobDown}
            />
            {drobDown ? (
              <div className="options">
                <div className="option">
                  <i className="fa-solid fa-user" />
                  <span>name</span>
                </div>
                <div className="option">
                  <i className="fa-solid fa-envelope" />
                  <span>email</span>
                </div>
                <div className="option">
                  <i className="fa-solid fa-phone" />
                  <span>phone</span>
                </div>
                <div className="option">
                  <i className="fa-solid fa-calendar" />
                  <span>date</span>
                </div>
              </div>
            ) : null}
          </div>
           
        </div>
      );
    case "Send an email":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                value={message}
                onChange={handleMessageChange}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          <div className="send-email slider-comp">
            <div className="email-input">
              <label htmlFor="send" style={{ color: isSendValid ? "" : "red" }}>
                Send an email to
              </label>
              <input
                id="send"
                type="text"
                placeholder="name@example.com"
                value={send}
                onChange={handleSendChange}
                className={isSendValid ? "" : "invalid"}
                style={{ borderColor: isSendValid ? "" : "red" }}
              />
            </div>
            <div className="email-input">
              <label htmlFor="cc" style={{ color: isCCValid ? "" : "red" }}>
                CC
              </label>
              <input
                id="cc"
                name="cc"
                type="email"
                placeholder="name@example.com"
                value={cc}
                onChange={handleCCChange}
                className={isCCValid ? "" : "invalid"}
                style={{ borderColor: isCCValid ? "" : "red" }}
              />
            </div>
            <div className="email-input">
              <label htmlFor="cc" style={{ color: isBCCValid ? "" : "red" }}>
                BCC
              </label>
              <input
                id="bcc"
                name="bcc"
                type="email"
                placeholder="name@example.com"
                value={bcc}
                onChange={handleBCCChange}
                className={isBCCValid ? "" : "invalid"}
                style={{ borderColor: isBCCValid ? "" : "red" }}
              />
            </div>
            <div className="email-subject slider-comp">
              <label htmlFor="subject">Subject</label>
              <textarea id="subject"></textarea>
            </div>
            <div className="email-markup slider-comp">
              <ReactQuill
                style={{ height: "200px", marginBottom: "50px" }}
                placeholder="Add what you want to be notified when an email is sent..."
                theme="snow"
                modules={modules}
                formats={formats}
              />
            </div>
          </div>
        </div>
      );
    case "Calender":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                value={"Calender"}
                onChange={handleMessageChange}
              />
              <p className="under-type">
                Opens a calendar UI in the chat interface through which the user
                can select their desired dater
              </p>
              <div className="date-selection slider-comp">
                <button
                  id="selection"
                  onClick={handleDateType}
                  style={{
                    color: dateType === "selection" ? "#2a7cff" : "gray",
                    borderBottom:
                      dateType === "selection" ? "3px solid #2a7cff" : "none",
                  }}
                >
                  Date selection
                </button>
                <button
                  id="exclude"
                  onClick={handleDateType}
                  style={{
                    color: dateType === "exclude" ? "#2a7cff" : "gray",
                    borderBottom:
                      dateType === "exclude" ? "3px solid #2a7cff" : "none",
                  }}
                >
                  Exclude dates
                </button>
              </div>
              {dateType === "selection" ? (
                <>
                  <div className="email-subject slider-comp">
                    <label htmlFor="subject">Show message</label>
                    <textarea
                      id="subject"
                      value={"What is your preferred date?"}
                    ></textarea>
                    <span className="under-input">
                      you can reference a variable by typing #
                    </span>
                  </div>
                  <div className="drop-down slider-comp">
                    <span className="upove-input">
                      Save the response in this variable
                    </span>
                    <input
                      className="text-box"
                      type="text"
                      readOnly
                      placeholder="Select"
                      onClick={handleDrobDown}
                    />
                    <span className="under-input">
                      you can select a variable that can be referenced later in
                      the conversation
                    </span>
                    {drobDown ? (
                      <div className="options">
                        <div className="option">
                          <i className="fa-solid fa-user" />
                          <span>name</span>
                        </div>
                        <div className="option">
                          <i className="fa-solid fa-envelope" />
                          <span>email</span>
                        </div>
                        <div className="option">
                          <i className="fa-solid fa-phone" />
                          <span>phone</span>
                        </div>
                        <div className="option">
                          <i className="fa-solid fa-calendar" />
                          <span>date</span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                <>
                  <div className="exclude-date slider-comp">
                    <label>Exclude specific dates</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <i
                        className="fa-solid fa-calendar"
                        onClick={handleDateDrob}
                        style={{
                          color: "#2a7cff",
                          fontSize: "1.1rem",
                          marginRight: "5px",
                        }}
                      />
                      <input
                        className="text-box"
                        type="text"
                        readOnly
                        placeholder="Select the days to be excluded"
                        value={date.toLocaleDateString()}
                      />
                    </div>
                    {dateDrop ? (
                      <DayPicker
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="drop-down slider-comp">
                    <span className="upove-input">Exclude All-Days</span>
                    <input
                      className="text-box"
                      type="text"
                      readOnly
                      placeholder="Select the days to be excluded"
                      onClick={handleDrobDown}
                    />
                    {drobDown ? (
                      <div className="days">
                        <label>
                          <input type="checkbox" />
                          Monday
                        </label>
                        <label>
                          <input type="checkbox" />
                          Sunday
                        </label>
                        <label>
                          <input type="checkbox" />
                          Tuesday
                        </label>
                        <label>
                          <input type="checkbox" />
                          Wednesday
                        </label>
                        <label>
                          <input type="checkbox" />
                          Thursday
                        </label>
                        <label>
                          <input type="checkbox" />
                          Friday
                        </label>
                        <label>
                          <input type="checkbox" />
                          Saturday
                        </label>
                      </div>
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    case "Buttons":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                ref={SendMessageRef}
                onChange={() => {
                  props.reRender();
                  props.data[props.index].text = SendMessageRef.current.value;
                }}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          <div className="slider-comp">
            <p className="message-description">Send this message</p>
            <div className="quill">
              <ReactQuill
                placeholder="what is your..."
                value={message}
                onChange={() => {
                  setMessage(handleEditorChange());
                }}
                theme="snow"
                modules={modules}
                formats={formats}
              />
            </div>
          </div>
          <div className="add-btn-list">
            {props.data[props.index].children.map((item, index) => (
              <div style={{ display: "flex" }}>
                <input
                  value={props.data[item].text}
                  onChange={(e) => {
                    props.data[item].text = e.target.value;
                    props.reRender();
                  }}
                ></input>
                <button
                  className="delet-btn"
                  onClick={() => {
                    if (index > 0) {
                      props.data[props.index].children.splice(index, 1);
                      props.data.splice(item, 1);
                      props.pathData.splice(
                        props.data[props.index].childrenR[index],
                        1
                      );
                      props.data[props.index].childrenR.splice(index, 1);
                      props.reRender();
                    }
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: index === 0 ? "auto" : "pointer",
                  }}
                >
                  <i
                    className="fa-regular fa-trash-can"
                    style={{ color: index === 0 ? "transparent" : "gray" }}
                  ></i>
                </button>
              </div>
            ))}
            <button onClick={props.addChild} style={{ width: "150px" }}>
              <i class="fa-solid fa-plus"></i>
              <p className="add-btn-text">Branch button</p>
            </button>
          </div>
        </div>
      );
    case "Branch":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                ref={SendMessageRef}
                onChange={() => {
                  props.reRender();
                  props.data[props.index].text = SendMessageRef.current.value;
                }}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          <div className="slider-comp">
            <p className="message-description">Send this message</p>
          </div>
          <div className="quill">
            <ReactQuill
              placeholder="what is your..."
              value={message}
              onChange={() => {
                setMessage(handleEditorChange());
              }}
              theme="snow"
              modules={modules}
              formats={formats}
            />
          </div>
          <button
            onClick={() => {
              props.addChild();
            }}
          ></button>
        </div>
      );
    case "Carousel":
      return (
        <div
          className={"slider"}
          style={{ right: props.isOpen ? "0" : "-350px" }}
        >
          <div className="slider-header">
            <button className="close" onClick={props.onClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="slider-comp">
            <div className="message-box">
              <input
                className="message"
                ref={SendMessageRef}
                onChange={() => {
                  props.reRender();
                  props.data[props.index].text = SendMessageRef.current.value;
                }}
              />
              <p className="message-description">
                Send message will only send messages to the user and not require
                a response back
              </p>
            </div>
          </div>
          <div className="slider-comp">
            <p className="message-description">Send this message</p>
          </div>
          <div className="quill">
            <ReactQuill
              placeholder="what is your..."
              value={message}
              onChange={() => {
                setMessage(handleEditorChange());
              }}
              theme="snow"
              modules={modules}
              formats={formats}
            />
          </div>
          <button
            onClick={() => {
              props.addChild();
            }}
          ></button>
        </div>
      );
  }
}
